import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from '../components/common/section';
import ContentHero from '../components/content-hero';
import SubFeatureDetail from '../components/subfeature-detail';

import Ready from '../components/ready';

import illuReady from '../images/illu-ready.svg';
import illuHero from "../images/create-without-limit_top.svg"
import illuHero2 from "../images/sub-product-tour-2.svg"

const ContentSample = () => {

  const templateAnimation = '/animations/2020-09-Ludus-08-template.json';
  const fontAnimation = '/animations/2020-09-Ludus-05-fonts_EDIT01.json';
  const smartBlocksAnimation = '/animations/2020-09-Ludus-07-smartBlock.json';
  const blendingAnimation = '/animations/2020-10-Ludus-blending.json';
  const colorsAnimations= '/animations/2020-10-Ludus-colors.json';

  const [currentBreakPoint, setCurrentBreakpointChildren] = useState({
    type: null,
    bp: null
  });

  return (
    <Layout
      socialDark={true}
      currentPage='create-without-limits'
      updateBreakpoint={setCurrentBreakpointChildren}
    >
      <SEO title="Create without limits" />

      <Section>

        <div
          style={{
            display: "block",
            position: "absolute",
            zIndex: "0",
            width: currentBreakPoint.type === 'mobile' ? '43%' : '24%',
            paddingBottom: currentBreakPoint.type === "mobile" ? '75%' : '27%',
            top: currentBreakPoint.type === 'mobile' ? '130px' : '190px',
            left: '0',
            background: `transparent url(${illuHero}) no-repeat 0 50%`,
            backgroundSize: "contain",
          }}
        />

        <ContentHero
          headlineLabel="Product tour"
          headlineUrl="/product-tour"
          title="Create without limits"
          text={`We want you to forget about the tool. Everyday we try to make Ludus as versatile as possible, pushing the technical limits to free your creative mind.<br />
          The road is very long, but we believe we've already achieved some milestones, and there is several things you can only do in Ludus....`}
        />
      </Section>

      <Section>

      <div
          style={{
            display: "block",
            position: "absolute",
            zIndex: "0",
            width: currentBreakPoint.type === "mobile" ? '40%' : '20%',
            paddingBottom: currentBreakPoint.type === "mobile" ? '75%' : '27%',
            top: '-5%',
            right: '0',
            background: `transparent url(${illuHero2}) no-repeat 100% 50%`,
            backgroundSize: "contain",
          }}
        />

        <SubFeatureDetail
          title="Magic editing"
          text="You can pretty much do anything with all types of content blocks: crop, corner radius, drop shadow, filters, blend modes or even skew! And you can apply these features to an image, a GIF or even a video. We really want to let you follow your limitless imagination. "
          animation={blendingAnimation}
          details={[
            {
              title: 'Edit anything the same way ',
              text: 'We try to let you edit any type of block exactly the same way as all the others. For example: if you can skew an image, you can skew a video or a chart as well.'
            },
            {
              title: 'Inspired by the best',
              text: 'We integrated the most common features from popular editing softwares (Figma, Sketch, photoshop). We took the best of them, and mixed it with the essential features from our favorite presentation tools. '
            },
            {
              title: 'Interactive',
              text: 'The most essential difference from graphic editing apps is Ludus produce interactive documents. You can browse your slides, play video, interact with 3D objects, mouse over a chart, browse a website directly from the same Ludus presentation.'
            }
          ]}
        />
      </Section>

      <Section
        light={true}
      >
        <SubFeatureDetail
          reverse={true}
          title="Color management"
          text="A Color library is always hard to maintain on long term. Sometimes you take a shortcut and use a slightly different color. Sometimes you have to use a new color for a new usage, and if you work together you end up with a beautiful mess.<br />In Ludus we organized the colors in three  categories: "
          animation={colorsAnimations}
          details={[
            {
              title: 'Personal colors',
              text: 'These colors are only visible to you. You can manage it exacly the way you want. It will be available in all the different workspaces (~ teams) of Ludus.'
            },
            {
              title: 'Document colors',
              text: `This library is a dynamical list of all the colors used in your current document. This can be super useful to check if you're not using a new color for no reason. `
            },
            {
              title: 'Team colors',
              text: 'This library of swatches is for your current team. When you save or delete a color, all your team will see the change. Usually you only save your key brand colors there, and they should not be changed too often.'
            }
          ]}
        />
      </Section>

      <Section>
        <SubFeatureDetail
          title="Templates"
          text="We know templates are always a frustration point between the ones producing them and the ones using them. Most of the time, templates are half broken, half used by your team and nobody really knows which one is the correct version, which one is used by who, etc."
          animation={templateAnimation}
          details={[
            {
              title: 'Synced!',
              text: 'Your templates are synced across all your presentation or your current workspace. That means if you update a template, all the presentations using that template can be updated to the last version. So there is basically only one version of a template: the last one.'
            },
            {
              title: `Define what's editable`,
              text: 'When you create a template you decide what will be editable eventually. When you decide that a text or an image is editable you give it a name. And the cool thing is that name will be used to match objects when the current template of a slide is replaced by another one. So all the updated objects will always remain!'
            },
            {
              title: 'Themes!?',
              text: `Ludus templates are arranged by themes. So you can change the theme of a presentation in one click. You can give each template a unique "type"; so when you switch the theme of a presentation we know which template should be remplaced by what. You're always in full control. `
            }
          ]}
        />
      </Section>

      <Section
        light={true}
      >
        <SubFeatureDetail
          reverse={true}
          title="Font management"
          text="When you work within a team, managing fonts are still a pain. Everyone should install them locally, make sure it's rendering the same on Windows, Mac or Linux. When you exchange them online you either end up in spam or with corrupted file... We are in 21st century, can't we do something?"
          animation={fontAnimation}
          details={[
            {
              title: 'Easy',
              text: `If you have a font you need to share with other you simply upload it in Ludus. You and your team can instantly use it... Is just that simple. You'll never have to worry about it anymore. It's there accessible to your team but of course to anyone "viewing" your presentations; exactly as you want it.`
            },
            {
              title: 'Google fonts',
              text: 'Of course you can use Google fonts, you can search for it from Ludus directly. And it will work the same way as a custom font: It will  be available to everyone in your team, for all your presentations.'
            },
            {
              title: 'Featured fonts',
              text: `We believe, designing a font is an art. And we should find a way to reward these artists. That's why you'll find exclusive font designed by great freelancers accessible to all our users for free. Now you know that when you start paying for Ludus, a small part of it goes also to font designers! `
            }
          ]}
        />
      </Section>

      <Section>
        <SubFeatureDetail
          title="Smart blocks"
          text={`You can see smart blocks as a way to quickly save, share and re-use assets across your team and presentations. `}
          animation={smartBlocksAnimation}
          details={[
            {
              title: 'Synced (again)',
              text: `But it's much more than that: these blocks are synced within all your slides, so if you update the original block, all the instances of the block are updated. `
            },
            {
              title: 'Smart resize',
              text: 'They are not restricted to a fixed size. We implemented a smart resize, so you can change the ratio of an object, it will always try to adapt the best way possible. So you can use the same object in very different layouts.'
            },
            {
              title: 'Text and image override',
              text: `If the parent smart block has an image or a text they'll be "overridable", meaning you can change that only for the child instance. But, it doesn't mean it's not synced anymore to the parent. Again, when the parent is updated, we'll update all children instances to reflect the changes, while maintaining the overridden properties.`
            },
            {
              title: 'Keep them organized',
              text: `Usually big teams mean big Smart Blocks library. That's why you can search for them in our Smart Menu, in a super efficient way. You can as well assign one or multiple tags to your Smart Blocks, to let you create the ideal library.`
            }
          ]}
        />
      </Section>

      <Section>
        <br /><br /><br /><br /><br /><br /><br /><br /><br />
      </Section>

      <Section
        light={true}
      >
        <div style={{
          display: (currentBreakPoint.bp === 'm' || currentBreakPoint.bp === 's' || currentBreakPoint.bp === 'xs') ? 'none' : 'block',
          position: 'absolute',
          zIndex: '4',
          width: '35%',
          paddingBottom: '35%',
          bottom: '0',
          right: '0',
          background: `transparent url(${illuReady}) no-repeat 100% 50%`,
          backgroundSize: 'contain',
        }} />
        <Ready />
      </Section>

      
    </Layout>
  );
};

export default ContentSample;
